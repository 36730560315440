import React, { Reducer, Suspense, useReducer } from 'react';
import { useCallback } from 'react';
import { MessageHandlerEvent, useMessageHandler } from '../../../controllers/AppController';
import { GenericMessage } from '@fluidprompter/core';
import { Screen } from '@fluidprompter/ipc-interfaces';

const SdiIpcDevToolsRenderer = React.lazy(() => import('./SdiIpcDevToolsRenderer'));

export enum ReducerActionTypes {
  openDialog = 'openDialog',
  closeDialog = 'closeDialog',
  appendLogStatement = 'appendLogStatement',
  clearLog = 'clearLog',
  setSdiProvider = 'setSdiProvider',
  setSdiAvailable = 'setSdiAvailable',
  setAvailableDevices = 'setAvailableDevices',
  setSelectedDevice = 'setSelectedDevice',
}
export interface IReducerAction {
  type: ReducerActionTypes;
  payload?: unknown;
}

export class ReducerAction {

  static appendLogStatement(logMsg: string): IReducerAction {
    return {
      type: ReducerActionTypes.appendLogStatement,
      payload: logMsg,
    };
  }

  static clearLog(): IReducerAction {
    return {
      type: ReducerActionTypes.clearLog,
    };
  }

  static setSdiProvider(providerName: string): IReducerAction {
    return {
      type: ReducerActionTypes.setSdiProvider,
      payload: providerName,
    };
  }

  static setSdiAvailable(sdiAvailable: boolean): IReducerAction {
    return {
      type: ReducerActionTypes.setSdiAvailable,
      payload: sdiAvailable,
    };
  }

  static setAvailableDevices(availableDevices: Screen[]): IReducerAction {
    return {
      type: ReducerActionTypes.setAvailableDevices,
      payload: availableDevices,
    };
  }

  static setSelectedDevice(selectedDevice: Screen | undefined): IReducerAction {
    return {
      type: ReducerActionTypes.setSelectedDevice,
      payload: selectedDevice,
    };
  }

}

export interface ISdiDevToolsState {
  isOpen: boolean;
  logMessages: string[];
  sdiProvider: string | undefined;
  sdiAvailable: boolean | undefined;
  availableDevices: Screen[];
  selectedDevice: Screen | undefined;
}
const defaultState: ISdiDevToolsState = {
  isOpen: false,
  logMessages: [],
  sdiProvider: undefined,
  sdiAvailable: undefined,
  availableDevices: [],
  selectedDevice: undefined,
};
const reducerFunction: Reducer<ISdiDevToolsState, IReducerAction> = function(state: ISdiDevToolsState, action: IReducerAction) {
  switch (action.type) {
    case ReducerActionTypes.openDialog:
      return {
        ...state,
        isOpen: true,
      };
    case ReducerActionTypes.closeDialog:
      return {
        ...state,
        isOpen: false,
      };
    case ReducerActionTypes.appendLogStatement: {
      const {
        logMessages,
      } = state;
      const logMsg = action.payload as string;

      const timestamp = new Date().toISOString();

      return {
        ...state,
        logMessages: [
          ...logMessages,
          `${timestamp} ${logMsg}`,
        ],
      };
    }
    case ReducerActionTypes.clearLog:
      return {
        ...state,
        logMessages: [],
      };
    case ReducerActionTypes.setSdiProvider:
      return {
        ...state,
        sdiProvider: action.payload as string,
      };
    case ReducerActionTypes.setSdiAvailable:
      return {
        ...state,
        sdiAvailable: action.payload as boolean,
      };
    case ReducerActionTypes.setAvailableDevices:
      return {
        ...state,
        availableDevices: action.payload as Screen[],
      };
    case ReducerActionTypes.setSelectedDevice:
      return {
        ...state,
        selectedDevice: action.payload as Screen,
      };
    default:
      throw new Error();
  }
};
export interface ISdiDevStateProps {
  state: ISdiDevToolsState;
  dispatch: React.Dispatch<IReducerAction>;
}

function SdiIpcDevTools() {

  const [state, dispatch] = useReducer(reducerFunction, defaultState);

  const showHelp = useCallback(async function (e: MessageHandlerEvent<GenericMessage>) {
    e.sendToPeers = false;

    dispatch({
      type: ReducerActionTypes.openDialog,
    });
  }, []);
  useMessageHandler('prompter.dev.sditools', showHelp);

  return (
    <Suspense>
      {state.isOpen && <SdiIpcDevToolsRenderer state={state} dispatch={dispatch} />}
    </Suspense>
  );
}

export default SdiIpcDevTools;