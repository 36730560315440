import React, { useEffect, useRef, useState } from 'react';
import useApplicationContext from '../../hooks/useApplicationContext';

import NoticeDesktopBeta from './NoticeDesktopBeta';
import NoticeEmailVerificationRequired from './NoticeEmailVerificationRequired';
import NoticeBillingUpdateRequired from './NoticeBillingUpdateRequired';
import NoticeSubscriptionCancelled from './NoticeSubscriptionCancelled';

import useResizeObserver from '@react-hook/resize-observer';
import './AppNoticeBar.scss';

const FIFTEEN_MINUTES_IN_MILLISECONDS = 15 * 60 * 1000;

function AppNoticeBar() {
  const {
    appContext,
  } = useApplicationContext(true);

  const {
    isLoading,
    isAuthenticated,
    userProfile,
  } = appContext;

  const [activeNotice, setActiveNotice] = useState<string | undefined>();
  useEffect(() => {
    //
    // Show app beta notice if we are running in an electron fluidprompterHost.
    //
    if(window.fluidprompterHost?.type === 'electron') {
      setActiveNotice('desktop_beta_release');
      return;
    }

    //
    // We won't show any notices while the app context is loading or if the user is not
    // authenticated or if the user profile is not yet loaded.
    //
    if(isLoading || !isAuthenticated || !userProfile) {
      setActiveNotice(undefined);
      return;
    }

    const {
      created_at,
      email_verified,
      subscription_next_amount,
      subscription_status,
      billing_expires_soon,
      billing_expired_already,
      fluidprompter_plan_cancel_at,
      fluidprompter_plan_canceled_at,
      fluidprompter_plan_cancellation_reason,
    } = userProfile;

    //
    // If the subscription is cancelled, either the user explicitly requested cancellation via the
    // stripe hosted billing portal or they were a paying customer but the last invoice could not
    // be charged to their card even after a few retries.
    //
    if(fluidprompter_plan_canceled_at) {
      // Convert seconds to milliseconds so that it is using the equivalent units as `Date.now()`
      // const cancellationDate = new Date(fluidprompter_plan_canceled_at * 1000);
      switch(fluidprompter_plan_cancellation_reason) {
        case 'cancellation_requested':
          setActiveNotice('subscription_cancelled_by_user');
          break;
        case 'payment_failed':
        case 'payment_disputed':
        default:
          setActiveNotice('subscription_cancelled_payment_failed');
          break;
      }
      return;
    }

    //
    // Possible `subscription.status` values are:
    // - incomplete,
    // - incomplete_expired,
    // - trialing,
    // - active,
    // - past_due,
    // - canceled,
    // - unpaid, or
    // - paused.
    //
    if(subscription_status === 'past_due') {
      setActiveNotice('subscription_past_due');
      return;
    }

    //
    // Payment method notices only apply if the user is on a paid plan with some amount due for
    // payment. We should not show payment method related notices for free users or users with
    // 100% discount if offered (sponsored or refunded users).
    //
    if(subscription_next_amount) {
      //
      // If the user's payment method is either expiring soon, or already expired, we want to prompt
      // the user to update their plan.
      //
      if(billing_expired_already) {
        setActiveNotice('billing_expired_already');
        return;
      }
      if(billing_expires_soon) {
        setActiveNotice('billing_expires_soon');
        return;
      }
    }

    //
    // If the user has not yet verified their email, show the verify email notice bar.
    // To avoid bothering new user sign-ups, we will wait until their account is at least
    // 15 minutes old before showing the email verification notice.
    //
    const accountCreatedAt = new Date(created_at as string);
    const accountAgeMilliseconds = Date.now() - accountCreatedAt.getTime();
    if(
      // We don't want to both the user with email verification until their account is more than 15
      // minutes old. This gives new first time users a chance to try things before they commit.
      accountAgeMilliseconds > FIFTEEN_MINUTES_IN_MILLISECONDS
        && email_verified !== true
    ) {
      setActiveNotice('email_verification');
      return;
    }
  }, [isLoading, isAuthenticated, userProfile]);

  //
  // Whenever the NoticeBarContent is resized, we need to save the current content height.
  //
  const noticeBarContentRef = React.createRef<HTMLDivElement>();
  const [noticesHidden, setNoticesHidden] = useState<boolean>(true);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const lastContentHeightRef = useRef<number>(0);
  const handleResizeEvent = React.useCallback((e: ResizeObserverEntry) => {
    const proposedNewHeight = e.contentRect.height;
    if(lastContentHeightRef.current === proposedNewHeight) {
      // No change in notice bar content height
      return;
    }
    lastContentHeightRef.current = proposedNewHeight;

    setContentHeight(proposedNewHeight);
    setNoticesHidden(false);
  }, []);
  useResizeObserver(noticeBarContentRef, handleResizeEvent);

  //
  // When the active notice changes, make sure we are showing the notice bar
  //
  useEffect(() => {
    setNoticesHidden(false);
  }, [activeNotice]);

  //
  // When we finished transitioning the max-height, we are other showing or hiding the notices.
  //
  const noticeBarContainerRef = React.createRef<HTMLDivElement>();
  React.useEffect(() => {
    const noticeBarContainerEl = noticeBarContainerRef.current;
    if(!noticeBarContainerEl) {
      return;
    }

    const onTransitionEnd = (e: TransitionEvent) => {
      //
      // If we just finished hiding the noticebar, clear the active notice
      //
      if(e.propertyName === 'max-height' && noticesHidden) {
        setActiveNotice(undefined);
      }
    };

    noticeBarContainerEl.addEventListener('transitionend', onTransitionEnd);

    return () => {
      noticeBarContainerEl.removeEventListener('transitionend', onTransitionEnd);
    };
  }, [noticeBarContainerRef, noticesHidden]);

  const billingPastDue = userProfile?.subscription_status === 'past_due';

  return (<div
    ref={noticeBarContainerRef}
    className='NoticeBarContainer'
    style={{
      maxHeight: noticesHidden ? 0 : `${contentHeight}px`,
    }}
  >
    <div
      ref={noticeBarContentRef}
      className={'NoticeBarContent'}
    >
      {activeNotice === 'subscription_cancelled_by_user' && <NoticeSubscriptionCancelled
        cancellation_requested={true}
        requestClose={() => {
          setNoticesHidden(true);
        }}
      />}
      {activeNotice === 'subscription_cancelled_payment_failed' && <NoticeSubscriptionCancelled
        requestClose={() => {
          setNoticesHidden(true);
        }}
      />}

      {/* If the fluidprompter host is desktop, show the beta app notice. */}
      {activeNotice === 'desktop_beta_release' && <NoticeDesktopBeta
        requestClose={() => {
          setNoticesHidden(true);
        }}
      />}

      {/* If the user's email address is not yet verified */}
      {activeNotice === 'email_verification' && <NoticeEmailVerificationRequired
        requestClose={() => {
          setNoticesHidden(true);
        }}
      />}

      {/* If the user's subscription is past due - we had trouble charging their card */}
      {/* If the user's payment method is either about to expire or has already expired */}
      {(activeNotice === 'billing_expires_soon'
        || activeNotice === 'billing_expired_already'
        || activeNotice === 'subscription_past_due')
        && <NoticeBillingUpdateRequired
          billingExpiredAlready={userProfile?.billing_expired_already === true}
          billingPastDue={billingPastDue}
          requestClose={() => {
            setNoticesHidden(true);
          }}
        />
      }
    </div>
  </div>);
}

export default AppNoticeBar;