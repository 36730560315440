/**
 * © Copyright 2025 FluidLabs Software Ltd.
 */
import { IPCServer, MessageChannel } from '@fluidprompter/ipc';

import {
  DisplayMode,
  IPCServers,
  ISdiProvider,
  SdiProvider,
  Screen,
} from '@fluidprompter/ipc-interfaces';

/**
 * The web provider for SDI is largely a 'no-op' implementation for the SDI provider interface.
 * It's only purpose is to provide feature availability information to the user interface.
 */
export class SdiProviderWebIPCServer implements ISdiProvider
{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;

  private _serverHandle: IPCServer<ISdiProvider>;

  constructor(IPCChannel: MessageChannel) {
    this._serverHandle = new IPCServer(IPCServers.SDI, IPCChannel, this);
  }

  async getProvider() {
    return SdiProvider.WEB;
  }

  /**
   * Allow the web interface to query if SDI features are available in the current context.
   * The Desktop (electron) app will allow Sdi feature availabilty.
   * The default Web Browser SDI provider will report this feature as unavailable.
   */
  async getSdiAvailability(): Promise<boolean> {
    return false;
  }

  /**
   * Enumerate the list of available SDI hardware devices currently available.
   */
  async enumerateDevices(): Promise<Screen[]> {
    return [
      {
        id: 'WebProvider_FakeDevice',
        displayName: 'Fake Device',
        modelName: 'Fake Device',
      }
    ];
  }

  /**
   * Enumerate the list of available display modes for a provided SDI hardware device.
   * @param deviceId Device Id from the list of devices returned by enumerateDevices()
   */
  async enumerateDeviceDisplayModes(deviceId: string): Promise<DisplayMode[]> {
    return [
      {
        name: 'WebProvider_FakeDisplayMode1',
        width: 1920,
        height: 1080,
        mode: '1920x1080',
        frameRate: 60,
      },
      {
        name: 'WebProvider_FakeDisplayMode2',
        width: 1280,
        height: 720,
        mode: '1280x720',
        frameRate: 60,
      },
    ];
  }

  async startStream(screenId: string, displayMode: DisplayMode, prompterId: string): Promise<void> {
    return;
  }

  async stopStream(screenId: string): Promise<void> {
    return;
  }
}