import { MessageChannel } from '@fluidprompter/ipc';
import { isElectronApp } from '../BrowserUtils';

/**
 * Message channel for relaying IPC messages between IPC server (renderer process)
 * and client (main process)
 */
export class ElectronRelayChannel implements MessageChannel {
  receivers: Array<{host: string, messageProcessor: CallableFunction}>;

  constructor() {
    this.receivers = [];
  }

  private onMessageHandler(data: unknown) {
    for (const receiver of this.receivers) {
      receiver.messageProcessor(data);
    }
  }

  send(instanceName: string, data: unknown): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if(!window.fluidprompterHost || !window.fluidprompterHost.sendIPCToHost) { // This will be undefined if not running inside Electron renderer process
        throw new Error('window.ReactNativeWebView not defined');
      }
      console.log(`Send to Electron main process - ${instanceName}`, data);
      window.fluidprompterHost.sendIPCToHost(data);
      resolve();
    });
  }

  addOnMessageListener(instanceName:string, listenerCallback: (data: unknown) => void) {
    this.receivers.push({
      host: instanceName,
      messageProcessor: listenerCallback.bind(this)
    });
  }

  public getMessageHandler(): (data: unknown) => void {
    return this.onMessageHandler.bind(this);
  }
}
