import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import lightTheme from '../../themes/LightTheme';
import { useTranslation } from 'react-i18next';

// import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
// import DialogTitle from '@mui/material/DialogTitle';
import DialogTitleWithClose from '../DialogTitleWithClose';
import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';

// import Stack from '@mui/material/Stack';
// import Alert from '@mui/material/Alert';

import Grid from '@mui/material/Grid';
// import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
// import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';

import ConnectDeviceButton, { ConnectDeviceButtonProps } from './ConnectDeviceButton';

import QrCode2Icon from '@mui/icons-material/QrCode2';
// import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import InfoIcon from '@mui/icons-material/Info';

import useApplicationContext from '../../hooks/useApplicationContext';
import useInputMiddleware, { InputMiddlewareProps } from '../../hooks/useInputMiddleware';

import useApplicationState from '../../state/ApplicationState';
import useBluetoothState from '../../state/BluetoothState';
import { shallow } from 'zustand/shallow';
import { useAppController, useMessageHandler } from '../../controllers/AppController';

import { DeviceConnectionType } from '../../devices/BaseDevice';
import AirturnRemote from '../../devices/airturn/AirTurnRemote';
// import IkanEliteRemote from '../../devices/ikan/IkanEliteRemote';
import YCOnionRemote from '../../devices/yconion/YCOnionRemote';
import NeewerRT110Remote from '../../devices/neewer/NeewerRT110Remote';
import MomanFS1Pedal from '../../devices/moman/MomanFS1Pedal';
import SpeedEditor from '../../devices/blackmagic/speededitor';
import ShuttlePro from '../../devices/contour/shuttlepro';

// const delay = (ms: number) => {
//   return new Promise(resolve => setTimeout(resolve, ms));
// };

const ConnectDeviceDialog = () => {

  const {
    appContext,
  } = useApplicationContext();

  const { t } = useTranslation('prompter');

  const appController = useAppController();

  const appState = useApplicationState(state => ({
    setMenuOpen: state.setMenuOpen,
    setRemoteDialogOpen: state.setRemoteDialogOpen,
    connectDeviceDialogOpen: state.connectDeviceDialogOpen,
    setConnectDeviceDialogOpen: state.setConnectDeviceDialogOpen,
  }), shallow);
  const isBluetoothAvailable = useBluetoothState(state => state.isBluetoothAvailable);

  useMessageHandler('app.connect.device', (e) => {
    e.sendToPeers = false;
    appState.setConnectDeviceDialogOpen(true);
  });

  const handleConnectDevice = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    connectionType: DeviceConnectionType,
    deviceType: string
  ) => {
    appState.setConnectDeviceDialogOpen(false);
    appState.setMenuOpen(false);

    switch(connectionType) {
      default:
      case DeviceConnectionType.Keyboard:
        appController.dispatchMessage('devices.generic.connect', deviceType);
        break;
      case DeviceConnectionType.Bluetooth:
        appController.dispatchMessage('devices.bluetooth.connect', deviceType);
        break;
      case DeviceConnectionType.Hid:
        appController.dispatchMessage('devices.hid.connect', deviceType);
        break;
    }
  };

  const handleBuyRemote = () => {
    //
    // It appears the additional options 'noopener,noreferrer' break the react-app by disconnecting dom or something.
    //
    // window.open('https://www.fluidprompter.com/remotes', '_blank', 'noopener,noreferrer');
    window.open('https://www.fluidprompter.com/remotes', '_blank');
  };

  const handleClose = () => {
    appState.setConnectDeviceDialogOpen(false);
  };

  //
  // Take over processing of human inputs while this modal dialog is open.
  //
  const inputMiddlewareProps = React.useMemo<InputMiddlewareProps>(() => {
    const results: InputMiddlewareProps = {
      exclusive: appState.connectDeviceDialogOpen,  // Prevent all user input hooks above this one from receiving input while this model is open.
    };

    return results;
  }, [appState.connectDeviceDialogOpen]);
  useInputMiddleware(inputMiddlewareProps);

  //
  // Construct our list of devices we might want to connect.
  //
  const isHidAvailable = navigator.hid !== undefined;
  const fluidprompter_plan = appContext.userProfile?.fluidprompter_plan;
  let hasPlanLevel: number | undefined;
  switch(fluidprompter_plan) {
    case 'free':
      hasPlanLevel = 0;
      break;
    case 'pro':
      hasPlanLevel = 1;
      break;
    case 'studio':
      hasPlanLevel = 2;
      break;
  }

  //
  // Gather all our device descriptors
  //
  const deviceImplementations = [
    // ...IkanEliteRemote.getDeviceDescriptors(t),
    ...AirturnRemote.getDeviceDescriptors(t),
    ...YCOnionRemote.getDeviceDescriptors(t),
    ...NeewerRT110Remote.getDeviceDescriptors(t),
    ...MomanFS1Pedal.getDeviceDescriptors(t),
    ...SpeedEditor.getDeviceDescriptors(t),
    ...ShuttlePro.getDeviceDescriptors(t),
  ];

  //
  // Produce props for the connect device buttons for each device type.
  //
  const connectDeviceButtonProps = deviceImplementations.map<ConnectDeviceButtonProps>((deviceDescriptor) => ({
    avatarSrc: deviceDescriptor.deviceIcon,
    primary: deviceDescriptor.deviceName,
    requiresBluetooth: deviceDescriptor.requiresBluetooth,
    requiresHid: deviceDescriptor.requiresHid,
    onClick: (e) => { handleConnectDevice(e, deviceDescriptor.connectionType, deviceDescriptor.deviceKey); },
    requiresPlanLevel: !process.env.REACT_APP_DEMO_MODE ? deviceDescriptor.requiresPlanLevel : undefined,
    priority: deviceDescriptor.priority,
  })).sort((a, b) => {
    let scoreA = (a.priority || 9) + (a.requiresPlanLevel || 0) * 10;
    if(a.requiresBluetooth && !isBluetoothAvailable) {
      // We need WebBluetooth and don't have it.
      scoreA += 100;
    }
    if(a.requiresHid && !isHidAvailable) {
      // We need WebHID and don't have it.
      scoreA += 100;
    }

    let scoreB = (b.priority || 9) + (b.requiresPlanLevel || 0) * 10;
    if(b.requiresBluetooth && !isBluetoothAvailable) {
      // We need WebBluetooth and don't have it.
      scoreB += 100;
    }
    if(b.requiresHid && !isHidAvailable) {
      // We need WebHID and don't have it.
      scoreB += 100;
    }

    return (scoreA - scoreB);
  });

  return (
    <ThemeProvider theme={lightTheme}>
      <Dialog
        // scroll='paper'
        onClose={handleClose}
        open={appState.connectDeviceDialogOpen}
      >
        <DialogTitleWithClose onClose={handleClose}>{t('connectdevicedialog.title')}</DialogTitleWithClose>
        {/* <Stack sx={{ width: '100%' }} spacing={2} paddingLeft={1} paddingRight={1} paddingBottom={1}>
          {!isBluetoothAvailable && <Alert variant="outlined" severity="error"
            action={
              <Button startIcon={<InfoIcon/>} color="inherit" size="small">
                MORE INFO
              </Button>
            }
          >
          Bluetooth is not available in this browser.

          </Alert>}
        </Stack> */}
        <DialogContent
          className="ForceScrollbar"
          dividers={true}
          sx={{
            padding: 0,
          }}
        >
          <Grid maxWidth={360} container /*padding={{ sm: 0, md: 1 }} spacing={{ sm: 0, md: 1 }}*/>
            <Grid item sm={12} /*md={6}*/>
              {/* <ConnectDeviceButton {...deviceInfo}
                hasPlanLevel={hasPlanLevel}
                hasBluetooth={isBluetoothAvailable}
                hasHid={isHidAvailable}
              /> */}

              <ListItemButton onClick={() => {
                appState.setConnectDeviceDialogOpen(false);
                appState.setMenuOpen(false);
                appState.setRemoteDialogOpen(true);
              }}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: 'transparent' }}>
                    <QrCode2Icon color="primary" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={t('connectdevicedialog.joindevice')} />
              </ListItemButton>
            </Grid>

            {connectDeviceButtonProps.map((deviceInfo, index) => (
              <Grid item sm={12} /*md={6}*/ key={`connectdevice2-${index}`}>
                <ConnectDeviceButton {...deviceInfo}
                  hasPlanLevel={hasPlanLevel}
                  hasBluetooth={isBluetoothAvailable}
                  hasHid={isHidAvailable}
                />
              </Grid>
            ))}

            {/* <ListItemButton onClick={() => {
              appState.setConnectDeviceDialogOpen(false);
              appState.setMenuOpen(false);
              bus.emit('prompter.windows.projector.start');
            }}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: 'transparent' }}>
                  <ScreenShareIcon color="primary" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Project to 2nd Monitor" />
            </ListItemButton> */}

            {/*
            <ListItemButton>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: 'transparent' }}>
                  <CastIcon color="primary" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Cast Prompter" />
            </ListItemButton>
            */}

            <Grid item sm={12} /*md={6}*/>
              <ListItemButton onClick={handleBuyRemote}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: 'transparent', fontSize: 48 }} variant="square">
                    <ShoppingCartIcon color="primary" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={t('connectdevicedialog.buyremote')} />
              </ListItemButton>
            </Grid>
          </Grid>
        </DialogContent>
        {/* <DialogActions>
          <Button startIcon={<ShoppingCartIcon color="primary" />} onClick={handleBuyRemote}>{t('connectdevicedialog.buyremote')}</Button>
          <Divider orientation="vertical" />
          <Button onClick={handleClose} autoFocus>{t('connectdevicedialog.done')}</Button>
        </DialogActions> */}
      </Dialog>
    </ThemeProvider>
  );
};

export default ConnectDeviceDialog;