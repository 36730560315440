import React from 'react';
import { ReactEditor, useSlateStatic, RenderElementProps, useFocused, useSelected } from 'slate-react';

import usePrompterSession from '../../../state/PrompterSessionState';
import { shallow } from 'zustand/shallow';

import classNames from 'classnames';

const ParagraphElement = (props: RenderElementProps) => {
  const { attributes, children, element } = props;

  const editor = useSlateStatic();
  const nodePath = ReactEditor.findPath(editor, element);

  const prompter = usePrompterSession(state => ({
    wordLimit: state.wordLimit,
    textMetrics: state.textMetrics, // We want to include textMetrics in our hook so the paragraph re-evaluates its word limit state when the script changes.
    getNodeTextMetricsByPath: state.getNodeTextMetricsByPath,
  }), shallow);

  const currentWordCount = prompter.getNodeTextMetricsByPath(nodePath)?.document_words || 0;
  const exceedsWordLimit = !process.env.REACT_APP_DEMO_MODE
    && prompter.wordLimit !== undefined
    && (currentWordCount >= prompter.wordLimit);

  return (
    <p
      {...attributes}
      className={classNames({ MaskedElement: exceedsWordLimit })}
    >{children}</p>
  );
};

export default ParagraphElement;