import React from 'react';

import FiberNewIcon from '@mui/icons-material/FiberNew';
import NoticeBar from './NoticeBar';

import { Trans, useTranslation } from 'react-i18next';

interface NoticeDesktopBetaProps {
  requestClose?: () => void;
}

function NoticeDesktopBeta(props: NoticeDesktopBetaProps) {
  const { requestClose } = props;

  const { t } = useTranslation('prompter');

  return (
    <NoticeBar
      type='warning'
      startIcon={<FiberNewIcon /*color='warning'*/ style={{ color: '#b6b600' }} />}
      requestClose={requestClose}
    ><p>The FluidPrompter desktop app is currently in beta! Some features may be missing or not quite ready. If you have feedback or questions, please email <a href="mailto:hello@fluidprompter.com" style={{ 'color': '#1976d2' }}>hello@fluidprompter.com</a>.</p></NoticeBar>
  );
}

export default NoticeDesktopBeta;