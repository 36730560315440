export var IPCServers;
(function (IPCServers) {
    IPCServers["BLE"] = "BLE";
    IPCServers["SDI"] = "SDI";
})(IPCServers = IPCServers || (IPCServers = {}));
export var IPCClients;
(function (IPCClients) {
    IPCClients["WebBleClient"] = "WebBleClient";
    IPCClients["WebSdiClient"] = "WebSdiClient";
})(IPCClients = IPCClients || (IPCClients = {}));
